<template>
  <div class="search">
    <div class="container">
      <div class="container-fluid">
        <div class="search-text">
          共 <span>{{ currentTotal }}</span> 条相关的结果
        </div>
      </div>
    </div>
    <template v-if="liveBoradRecordedArr.length > 0">
      <CourseRecom :liveBoradRecordedArr="liveBoradRecordedArr"></CourseRecom>
    </template>
    <template v-else>
      <AbsolutelyEmpty></AbsolutelyEmpty>
    </template>
    <div class="page" v-if="currentTotal > 16">
      <el-pagination
        background
        :page-size="16"
        layout="prev, pager, next"
        @current-change="currentChange"
        :total="currentTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import CourseRecom from '@/components/courseRecom/index';
import AbsolutelyEmpty from '@/components/AbsolutelyEmpty/index';
import { homePageSearch } from '@/api/api';
import list from '@/mixins/list';
export default {
  name: 'Search',
  mixins: [list],
  components: {
    CourseRecom,
    AbsolutelyEmpty
  },
  data() {
    return {
      search: '',
      searchValue: '',
      liveBoradRecordedArr: []
    };
  },
  watch: {
    $route(val, oldVal) {
      this.searchValue = val.query.searchValue;
    },
    searchValue(val, oldVal) {
      this.pageNum = 1;
      this.getHomePageSearch(val);
    }
  },
  methods: {
    loadData() {
      this.getHomePageSearch(this.$route.query.searchValue);
    },
    getHomePageSearch(activityName) {
      const all = `pageNum=${this.pageNum}&pageSize=16&activityName=${activityName}&courseTypeIds=&learningSectionIds=&courseContentIds=`;
      const jsonData = {
        all: all
      };
      const param = this.getParams(jsonData);
      homePageSearch(param).then((res) => {
        if (res.code == 200) {
          const { list, total } = res.data;
          this.liveBoradRecordedArr = list;
          this.currentTotal = total;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.search {
  box-sizing: border-box;
  .search-text {
    margin-top: 30px;
    color: @TEXT-COLOR-888888;
    .fontSize(@TEXT-SIZE-14);
    margin-bottom: 20px;
    span {
      color: @TEXT-COLOR-00BD92;
      .fontSize(@TEXT-SIZE-18);
      font-weight: bold;
    }
  }
  .comment();
}
</style>
