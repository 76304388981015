<template>
  <div class="absolutely-empty">
    <div class="bg" :style="`background: url(${img}) no-repeat 0px 0px`"></div>
    <div class="text">{{ title }}</div>
  </div>
</template>
<script>
export default {
  name: 'AbsolutelyEmpty',
  props: {
    img: {
      type: String,
      default: require('@/assets/images/learn/table-be-img.png')
    },
    title: {
      type: String,
      default: '暂无相关课程！'
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.absolutely {
  &-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 400px;
    .bg {
      width: 280px;
      height: 160px;
      margin-bottom: 20px;
    }
    .text {
      font-size: 16px;
      color: @TEXT-COLOR-888888;
      text-align: center;
    }
  }
}
</style>