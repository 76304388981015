export const live_tag = code => {
  switch (code) {
    case 'LIVING': return '直播中'
    case 'REVIWE': return '直播回放'
    case 'NOT_START': return '直播未开始'
    default: return '待回放'
  }
}
export const course_classification = code => {
  switch (code) {
    case 'LIVE_BROADCAST': return '公开课'
    case 'UPLOAD_VIDEO': return '精品课'
    case 'ONSITE_TEACHING': return '面授课'
    default: return '资讯'
  }
}
export const course_state = code => {
  switch (code) {
    case 'NOT_START': return '直播未开始'
    case 'RUNNING': return '直播中'
    case 'OVER': return '直播已结束'
    case 'PLAYBACK': return '直播回放'
    default: return '--'
  }
}